@use "./reset";
@use "./variables" as *;
@use "./mixins" as *;
@use "./media" as *;


.section {
  width: 100%;
  display: flex;
  justify-content: center;

  &--header {
    background-color: $primaryColor1;
    align-items: center;
  }

  &--home-hero {
    background-color: $primaryColor1;
  }

  &--home-intro {
    background-color: $primaryColor3;
  }

  &--home-testimonial {
    min-height: 64.8rem;
    background-image: url('../../assets/images/testibackground.png');
    background-size: cover;
    background-position: center;
    background-repeat: none;
  }

  &--footer {
    background-color: $primaryColor1;
    margin-top: auto;
  }

  &--about-intro {
    background-color: $primaryColor3;
  }

  &--about-hero {
    height: 26rem;
    background-image: url("../../assets/images/about-hero-image.png");
    background-color: $primaryColor3;
    background-size: cover;
    background-blend-mode: multiply;
    background-position: 0 55%;
  }

  &--motivation {
    background-color: $primaryColor3;
  }

  &--image-banner {
    height: 41.6rem;
    background-image: url("../../assets/images/about-image-banner.png");
  }

  &--mission {
    background-color: $primaryColor3;
  }

  &--explore {
    background-color: $primaryColor3;
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
  }

  &--booking {
    background-color: white;
  }

  &--profile-top {
    background-color: $primaryColor3;
  }

  &--signup{
    background-color: $primaryColor3;
    flex-direction: column;
    flex-grow: 1;
  }
}

.body-wrap {
  width: 100%;
  @include breakpoint-margin-limits;
  display: flex;

  &--header {
    height: 10rem;
    align-items: center;
  }

  &--signup{
    margin-top: 0;
  }
}

.hidden {
  display: none;
}

.input {
  height: 3.6rem;
  width: 100%;
  border-radius: 2rem;
  padding: 0 1.6rem;
  outline: none;
  font-size: 1.3rem;
  line-height: 2rem;
  font-family: "inter";
  border: 1px solid #bdc5d5;

  @include tablet {
    height: 3.8rem;
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  &--search {
    background: url(../../assets/images/Search.svg) no-repeat $primaryColor3;
    background-position: right 0.8rem center;

    @include tablet {}
  }
}

.button {
  font-size: 1.6rem;
  background-color: $primaryColor3;
  padding: 0.8rem 2.4rem;
  border-radius: 5rem;
  min-width: max-content;
  cursor: pointer;
  gap: 8px;

  &--hero {
    background-color: $primaryColor4;
    color: $primaryColor3;
    width: 19rem;
  }

}