@font-face {
    font-family: 'inter';
    src: url("../../assets/fonts/Inter-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'inter';
    src: url("../../assets/fonts/Inter-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}