@use "../../styles/partials/global" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/media" as *;

.hero {
    display: flex;
    padding: 10.5rem 0;
    justify-content: space-between;
    align-items: center;

    &__left {
        margin-right: 5rem;
    }

    &__text {
        color: $primaryColor3;
        padding-bottom: 4rem;
    }
}

.home-intro {
    padding: 12rem 0 19rem 0;
    display: flex;
    width: 100%;
    flex-direction: column;

    &__header {
        text-align: center;
        margin-bottom: 5.5rem;
    }

    &__top,
    &__bottom {

        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 1rem 0 1rem 0;
        margin: 0 -1rem;

        &__left,
        &__right {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            margin: 1rem 1rem;
        }
    }

    &__image{
        display: flex;
        max-height: 42.2rem;
        height: 35rem;
        min-height: 35rem;
    }

    &__text{
        display: flex;
        max-height: 21.1rem;
        height: 18rem;
        min-height: 15rem;

    }
}

.testimonials {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__header {
        margin: 15rem 0 7rem 0;
        color: $primaryColor3;
    }

    &__container{
        width: 100%;
        display: flex;
        justify-content: space-around;
        gap: 1rem;
    }

    &__card{
        border-radius: 1.5rem;
    }
}
