@use "../../styles/partials/global" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/media" as *;


.explore {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

}

.mentor{
    padding: 12rem 0 11rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__heading{
        margin-bottom: 2.4rem;
    }
}

.mentors {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 10rem;

    &__popular,
    &__topmatch{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-bottom: 9rem;
    }

    &__title-container {
        display: flex;
        justify-content: space-between;
    }

    &__title{
        font-weight: bold;
    }

    &__viewmore{
        font-size: 1.6rem;
        text-decoration: underline;
        font-weight: bold;
    }

    &__list {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;        
    }
}