@use "./styles/partials/variables" as *;
@use "./styles/partials/global" as *;
@use "./styles/partials/media" as *;
@use "./styles/partials/mixins" as *;

body {
  min-width: 32rem;
}

.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh
}
