@use "./variables" as *;

@mixin tablet {
  @media (min-width: $breakpoint-medium) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $breakpoint-large) {
    @content;
  }
}