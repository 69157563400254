@use "../../styles/partials/global" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/media" as *;

.signup{
    width: 100%;
    padding: 12rem 0 13rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    

    &__logo{
        height: 6.2rem;
        margin-bottom: 1.4rem;
    }

    &__header {
        margin-bottom: 7rem;
    }

    &__container {
        display: flex;
        flex-direction: row;
        gap: 5rem;
        margin-bottom: 10rem;
    }

    &__mentor,
    &__mentee {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    &__signin{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}