@use "../../styles/partials/global" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/media" as *;

.about-intro{
    width: 100%;
    padding: 11.5rem 0;
 
    &__text{
        margin-top: 2rem;   
        width: 65%;
    }
}

.motivation {
    width: 100%;
    padding: 15rem 0 7rem 0;
    display: flex;
    flex-direction: column;
    align-content: center;

    &__top {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 7rem;
        padding-bottom: 10rem;
        align-items: center;
    }
    
    &__title{
        margin-bottom: 2rem;
    }

    &__supporttitle {
        margin: auto;
        margin-bottom: 5rem;
        text-align: center;
        width: 73%;
    }

    &__supporttext{
        text-align: center;
        width: 74%;
        margin: auto;
    }
}

.mission{
    padding: 17rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__card{
        height: 44.4rem;
        margin: 7rem 0 4rem 0;
    }

    &__text{
        text-align: center;
        width: 70%;
        margin: auto;
    }
}