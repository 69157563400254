@use "../../styles/partials/global" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/media" as *;


.logo {
    height: 6rem;
}

.header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;

    &__nav{
        display: flex;
        flex-direction: row;
        align-items: center;
        color: white;
    }
}

.navlink {
    color: white;
    font-weight: bold;
    margin-right: 7rem;
    font-size: 1.6rem;
    line-height: 1.9rem;

}

.active {
    color: $primaryColor4;
    text-decoration: underline;
    text-underline-offset: 1rem;
    text-decoration-thickness: 3px;
    
  }