@use "../../styles/partials/global" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/media" as *;


.booking {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 13rem 0 37rem 0;

    &__title{
        margin-bottom: 2rem;
    }

    &__text {
        margin-bottom: 5.5rem;
    }
}